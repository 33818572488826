import React from "react"
import { css } from "@emotion/core"
import { useStaticQuery, Link, graphql } from "gatsby"
import { rhythm } from "../utils/typography"

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <section>
      <div css={css` margin: 0 auto; max-width: 800px; padding: ${rhythm(1.0)}; padding-top: ${rhythm(1.5)}; `}>
        <Link to={`/`} css={css`text-decoration: underline; color: inherit;`}>
          <h3 css={css` margin-bottom: ${rhythm(2)}; display: inline-block; font-style: normal; text-decoration: underline; `}>
            {data.site.siteMetadata.title}
          </h3>
        </Link>
        <div css={css`float: right;`}>
          <Link to={`/about/`}>自己紹介</Link>
          <a href="/rss.xml" css={css`margin-left: 5px;`}>RSS</a>
        </div>
        {children}
      </div>
      <div css={css`width: 100%; text-align: center; background: #eee; padding: ${rhythm(1.0)}`}>
        <small>
          &copy; blog.alea12.net
        </small>
      </div>
    </section>
  )
}

